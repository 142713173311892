<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Categorias de Conteúdo - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'contentCategories'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-content-category :contentCategory="contentCategory" :update="true"></form-content-category>
</span>
</template>

<script>
import FormContentCategoryComponent from "./partial/FormContentCategoryComponent";

export default {
    name: "EditContentCategoryComponent",
    created() {
        this.loadContentCategory()
    },
    props: {
        cont_c_id: {
            require: true
        }
    },
    data() {
        return {
            contentCategory: {
                cont_c_id: '',
                language_id: '1',
                content_section_id: '',
                cont_c_nome: '',
                cont_c_slug: '',
                cont_c_ordem: 0,
                cont_c_imagem: '',
                cont_c_descricao: '',
                cont_c_status: false,
            },
        }
    },
    methods: {
        loadContentCategory() {
            this.$store.dispatch('loadContentCategory', this.cont_c_id)
                .then(response => this.contentCategory = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formContentCategory: FormContentCategoryComponent
    }
};
</script>

<style scoped>

</style>
