<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <v-radio-group v-model="contentCategory.language_id" :column="false">
                        <v-radio color="lime" class="mr-4" v-for="(lang, index) in languages" :key="index" :label="lang.lang_descricao" :value="lang.lang_id"></v-radio>
                    </v-radio-group>

                </v-col>

                <v-col cols="10" xs="12" sm="12" md="8" lg="10" xl="10">
                    <v-row>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-select v-model="contentCategory.content_section_id" :items="loadContentSections" item-text="cont_sec_nome" item-value="cont_sec_id" label="Selecione uma seção..." :rules="rules.contentSectionRules" required outlined></v-select>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentCategory.cont_c_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentCategory.cont_c_ordem" label="Ordem" outlined></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="contentCategory.cont_c_descricao" label="Descrição" outlined></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-switch v-model="contentCategory.cont_c_status" label="Status"></v-switch>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                {{btnLabel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="2" xs="12" sm="12" md="4" lg="2" xl="2">

                    <v-layout row wrap>
                        <v-flex>
                            <v-flex d-flex v-if="preview || contentCategory.cont_c_imagem" justify-center>
                                <v-card>
                                    <v-img v-if="preview" class="img-preview" :src="preview" width="100">
                                    </v-img>

                                    <v-img v-if="contentCategory.cont_c_imagem && !preview" class="img-preview" :src="`${base_url}upload/content_category/p/${contentCategory.cont_c_imagem}`">
                                    </v-img>

                                    <v-card-actions class="justify-center">
                                        <v-btn icon>
                                            <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                        </v-btn>
                                        <v-btn icon>
                                            <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-flex>

                            <v-flex d-flex justify-center>
                                <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                                <v-btn v-if="!preview && !contentCategory.cont_c_imagem" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                                    Adicionar imagem
                                    <v-icon right dark>cloud_upload</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-flex>
                    </v-layout>
                </v-col>

            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    created() {
        this.$store.dispatch('loadLanguages');
        this.$store.dispatch('loadContentSections');
    },
    computed: {
        languages() {
            return this.$store.state.languages.items.data;
        },
        loadContentSections() {
            return this.$store.state.contentsections.items.data;
        }
    },
    props: {
        contentCategory: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    cont_c_id: '',
                    language_id: 1,
                    content_type_id: '',
                    cont_c_nome: '',
                    cont_c_slug: '',
                    cont_c_ordem: 0,
                    cont_c_imagem: '',
                    cont_c_descricao: '',
                    cont_c_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    name: "FormContentCategoryComponent",

    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 200) || 'Nome precisa ter ate 200 Caracteres'
                ],
                contentSectionRules: [
                    v => !!v || 'Campo Seção é obrigatório',
                ],
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateContentCategory' : 'storeContentCategory';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                const formData = new FormData();

                if (this.upload !== null)
                    formData.append('cont_c_imagem', this.upload);

                formData.append('cont_c_id', this.contentCategory.cont_c_id);
                formData.append('language_id', this.contentCategory.language_id);
                formData.append('content_section_id', this.contentCategory.content_section_id);
                formData.append('cont_c_nome', this.contentCategory.cont_c_nome);
                formData.append('cont_c_slug', this.contentCategory.cont_c_slug);
                formData.append('cont_c_ordem', this.contentCategory.cont_c_ordem);
                formData.append('cont_c_descricao', this.contentCategory.cont_c_descricao);
                formData.append('cont_c_status', this.contentCategory.cont_c_status);

                this.$store.dispatch(action, formData)
                    .then(() => {
                        this.$router.push({
                            name: 'contentCategories'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null;
            this.upload = null;
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
    margin: 0 auto;
}
</style>
